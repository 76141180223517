.contactpersons {
  padding: rem(50px 0);
}

.contactpersons__item {
  margin-bottom: rem(50px);
}

.team {
  position: relative;
  padding: rem(50px 0);
}

.team * {
  color: #FFF!important;
}

.team__left {
  position: relative;
  z-index: 1;
}

.team__right {
  position: relative;
  z-index: 1;
}

.team:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparentize($theme-primary,0.2);
  z-index: 0;
}

.team__item {
  position: relative;
  height: 300px;
  max-width: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: rem(0 auto 30px auto);
  overflow: hidden;
}

.team__intro {
  padding-bottom: rem(60px);
  text-align: center;
}

.team__intro:after {
  content: "";
  display: block;
  position: relative;
  width: rem(230px);
  height: 2px;
  background-color: #FFF;
  top: 20px;
  left: 50%;
  margin-left: rem(-115px);
}

.team__item-info {
  position: absolute;
  text-align: center;
  background-color: transparentize($theme-primary,0.2);
  left: 0;
  right: 0;
  bottom: 0;
  top: rem(260px);
  overflow: hidden;
  transition: all 0.3s;
}

.team__item-name {
  font-size: 20px;
  padding: rem(6px 0);
}

.team__item:hover .team__item-info,
.team__item:focus .team__item-info {
  top: 0;
}

.team__item:hover .team__item-infowrapper,
.team__item:focus .team__item-infowrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.team__item-infowrapper:hover {
  cursor: default;
}

.team h2 {
  font-size: rem(50px);
}

.flex-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  .team {
    padding: rem(90px 0);
  }

  .team__intro {
    padding: rem(0 50px 110px 50px);
  }

  .team__intro:after {
    top: 40px;
  }

  .team.team--slider .team__intro {
    text-align: left;
  }

  .team.team--slider .team__intro:after {
    left: 0;
    margin-left: 0;
  }

  .team.team--slider .slick-dots {
    bottom: rem(-20px)
  }

  .team.team--slider .slick-dots li button:after {
    border-color: #FFF;
  }

  .team.team--slider .slick-dots li.slick-active button:after {
    background-color: #FFF;
  }

}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {

  .team__intro {
    padding: rem(0 75px 110px 75px);
  }

  .team.team--slider .team__item {
    width: rem(265px);
    height: rem(265px);
  }

  .team.team--slider .team__item-info {
    top: rem(225px);
  }

  .team.team--slider .team__item:hover .team__item-info,
  .team.team--slider .team__item:hover .team__item-info {
    top: 0;
  }

}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {

  .team__intro {
    padding: rem(0 100px 110px 100px);
  }

  .team.team--slider .team__slider {
    max-width: rem(865px);
  }

}