// General: Reset List Styles
nav ul {
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}


header {
  min-height: rem(60px);
}

// Meta Navigation
.metanav {
  position: relative;
  background: $theme-secondary;
  height: rem(40px);
  z-index: 100;
}
.metanav__container,
.metanav__row,
.metanav__col {
  height: 100%;
}
.metanav__list {
  position: relative;
  float: right;
  top: 50%;
  transform: translateY(-50%);
}
.metanav__listitem {
  float: left;
}

.metanav__listitem-link {
  display: inline-block;
  color: #FFF;
  margin-left: rem(30px);
  font-size: rem(14px);
  height: 40px;
  padding: rem(11px 0);
}


.metanav__listitem-link--icon i.fa {
  padding-right: rem(10px);
  display: inline-block;
}

.metanav.metanav--textonly .metanav__listitem-link--icon i.fa {
  display: none;
}

.mainnav {
  position: fixed;
  width: 100%;
  height: rem(60px);
  background: #fff;
  z-index: 100;
}

.mainnav.mainnav--transparent {
  background: transparentize($theme-primary, 0.2);
}

.mainnav__logo {
  position: relative;
  width: rem(220px);
  height: rem(60px);
  float: left;
  padding: rem(10px 0);
  z-index: 9999;
  left: 0;
}

.mainnav.logo-right .mainnav__logo {
  left: 0;
}

.mainnav__logo figure {
  max-width: 100%;
  max-height: 100%
}

.mainnav__logo figure img {
  max-width: 100%;
  max-height: rem(40px);
  position: relative;
}

.mainnav__menu-icon {
  float: right;
  top: rem(8px);
  z-index: 9999;
  right: rem(-10px);
}

.mainnav__list1 {
  display: none;
  background: $theme-primary;
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  z-index: 9998;
  overflow-y: auto;
  border-top: rem(1px) solid rgba(255,255,255,0.3)
}

.mainnav__list2 {
  display: none;
  background: lighten($theme-primary,5%);
}

.mainnav__list3 {
  display: none;
  background: lighten($theme-primary,10%);
}

.mainnav__listitem1 {
  position: relative;
}

.mainnav__listitem1-link {
  display: block;
  position: relative;
  padding: rem(10px 10px 10px 20px);
  border-bottom: rem(1px) solid rgba(255,255,255,0.2);
  font-size: rem(16px);
  color: $text-color-light;
}

.mainnav__listitem1-link:hover,
.mainnav__listitem1-link:focus,
.mainnav__listitem1-link:active {
  color: #FFF;
  background: rgba(255,255,255,0.1);
}

.icon-unfold {
  position: absolute;
  right: 0;
  top: 0;
  width: rem(64px);
  height: rem(42px);
  z-index: 1000;
  cursor: pointer;
  text-align: center;
  border-left: rem(1px) solid rgba(255,255,255,0.2);
}

.icon-unfold:before {
  display: block;
  font-family: FontAwesome;
  content: "\f107";
  color: #fff;
  font-size: rem(32px);
  transition: transform 0.3s;
}

.icon-unfold.icon-unfold--down:before {
  transform: rotate(180deg);
}

.mainnav__listitem2 {
  position: relative;
}

.mainnav__listitem2-link:hover,
.mainnav__listitem2-link:focus,
.mainnav__listitem2-link:active {
  color: #FFF;
  background: rgba(255,255,255,0.1);
}

.mainnav__listitem2-link {
  display: block;
  padding: rem(8px 10px 8px 20px);
  color: $text-color-light;
  border-bottom: rem(1px) solid rgba(255,255,255,0.5);
}

.mainnav__list2 .icon-unfold {
  height: rem(38px);
}

.mainnav__list2 .icon-unfold:before {
  font-size: rem(26px);
}

.mainnav__listitem3-link {
  display: block;
  padding: rem(8px 10px 8px 30px);
  color: $text-color-light;
  border-bottom: rem(1px) solid rgba(255,255,255,0.5);
}

.mainnav__listitem3-link:hover,
.mainnav__listitem3-link:focus,
.mainnav__listitem3-link:active {
  color: #FFF;
  background: rgba(255,255,255,0.1);
}

.metanav-mobile__item {
  background: $theme-secondary;
}

.metanav-mobile__item-link {
  display: block;
  color: #FFF;
  padding: rem(5px 10px 5px 20px);
}

.metanav-mobile__item.is--first .metanav-mobile__item-link {
  padding-top: rem(10px);
}

.metanav-mobile__item.is--last .metanav-mobile__item-link {
  padding-bottom: rem(15px);
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .mainnav {
    position: relative;
    height: rem(60px);
    margin-top: 0;
    background-color: #fff;
  }

  .mainnav > .container {
    position: relative;
    height: 100%;
  }

  .mainnav__list1 {
    left: 50%;
    top: rem(60px);
    border: none;
    transition: none;
  }

  .mainnav__logo {
    height: 100%;
    padding: rem(20px 0);
  }

  .mainnav__logo figure {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .mainnav__logo figure img {
    position: absolute;
    bottom: -45px;
    left: 0;
    height: auto;
    max-height: rem(110px);
  }

  .mainnav__menu-icon {
    top: rem(10px);
  }

  .mainnav__listitem1-link {
    color: $text-color-light;
  }

  .mainnav__listitem1-link:hover,
  .mainnav__listitem1-link:focus,
  .mainnav__listitem1-link:active {
    color: $theme-primary;
    background: rgba(255,255,255,0.0);
  }

  header {
    //height: rem(100px);
  }

  header.fixed {
    height: rem(100px);
  }

  header.fixed .mainnav {
    position: fixed;
    height: rem(60px);
    top: rem(-60px);
    margin-top: rem(60px);
    transition: margin-top 0.3s;
    border-bottom: 1px solid #5c4b43;
  }

  header.fixed .mainnav__logo {
    padding: rem(10px 0);
  }

  header.fixed .mainnav__logo figure img {
    height: rem(50px);
    bottom: rem(-5px);
  }

  header.fixed .mainnav__menu-icon {
    top: rem(8px);
  }

  header.fixed .mainnav__list1 {
    top: rem(38px);
  }

}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .container {
    width: 980px;
  }

  .mainnav {
    height: rem(90px);
  }

  .mainnav__logo {
    width: rem(200px);
  }

  .mainnav__logo figure img {
    max-height: rem(140px);
    max-width: rem(160px);
    position: relative;
    //top: 50%;
    //transform: translateY(-50%);
    bottom: 45px;
  }

  header.fixed .mainnav__logo figure img {
    bottom: rem(5px);
  }

  .mainnav.logo-right .mainnav__logo figure img {
    float: right;
  }

  .mainnav__list1 {
    display: block!important;
    position: relative;
    top: rem(68px);
    left: 0;
    float: left;
    width: rem(750px);
    background: none;
    border: none;
    overflow: visible;
  }

  .mainnav__listitem1 {
    float: left;
    width: auto;
    margin-right: 15px;
  }

  .flexbox .mainnav__list1 {
    display: flex!important;
    justify-content: flex-end;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    float: none;
  }

  .flexbox .mainnav.logo-right .mainnav__list1 {
    justify-content: flex-start;
  }

  .flexbox .mainnav.logo-right .mainnav__listitem1 {
    margin-left: 0;
    margin-right: rem(40px);
  }

  .flexbox .mainnav__listitem1 {
    margin: rem(0 0 0 50px);
    float: none;
  }

  .mainnav__listitem1-link {
    border: none;
    font-size: rem(18px);
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    color: $theme-primary;
    font-weight: 700;
  }

  .mainnav__listitem1-link:hover {
    background: none;
  }

  .mainnav__listitem1:hover .mainnav__list2 {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    background: $theme-primary;
    width: auto;
    padding: rem(10px 0);
    margin-top: -1px;
    min-width: 100%;
  }

  .mainnav__listitem2-link {
    border: none;
    padding: rem(8px 30px 8px 20px);
    font-size: rem(14px);
  }

  .fixed .mainnav {
    position: fixed;
    height: rem(80px);
    top: rem(-80px);
    margin-top: rem(80px);
    z-index: 1000;
  }

  .fixed .mainnav.mainnav--transparent {
    background: $theme-primary;
  }

  .fixed .mainnav__list1 {
    top: rem(22px);
  }

  .fixed .mainnav__listitem1-link {
    padding: rem(5px 0 25px 0);
  }

  .fixed .mainnav__listitem1:hover .mainnav__list2 {
    top: (58px);
  }

  .fixed .mainnav__logo {
    padding-bottom: rem(10px);
  }

  .fixed .mainnav__logo figure img {
    max-height: rem(60px);
  }

  .flexbox .mainnav.logo-right .mainnav__list1 {
    margin-left: 0;
    margin-right: rem(30px);
    float: left;
    width: rem(720px);
  }


}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  header {
    //height: rem(250px);
  }

  .mainnav {
    height: rem(90px);
  }

  .mainnav__logo {
    width: rem(250px);
  }

  .mainnav__logo figure img {
    max-width: rem(180px);
    max-height: rem(140px);
  }

  .mainnav__list1 {
    width: rem(890px);
    margin-left: rem(30px);
    top: rem(137px);
  }

  .flexbox .mainnav.logo-right .mainnav__list1 {
    margin-left: 0;
    margin-right: rem(30px);
    float: left;
    width: rem(860px);
  }

}