// Custom Project Styles
//@import "jquery.fancybox.scss"; KEINE LIZENZ!, bitte magnific popup verwenden
@import "connectiv/z-index.scss";
@import "connectiv/sprite.scss";
@import "connectiv/custom-vars.scss";
@import "connectiv/conn-basics.scss";

// Font-Awesome
@import "font-awesome/font-awesome.scss";

// Unitegallery Styles
@import "unitegallery/unite-gallery";
@import "unitegallery/ug-theme-default";

//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "bootstrap/bootstrap";

// Mixin import & Configuration.cards__item-headline
@import "mixins/mixins";
$rem-baseline: 10px;

// Theme Import
@import "theme/config";


//Slick Slider
@import "slick/slick.scss";
.slick-slide:focus { outline: none; }
.slick-arrow { position: absolute; border: none; background: transparent; font-size: 0; width: rem(44px); height: rem(44px); background-repeat: no-repeat; background-position: center; top: 50%; transform: translateY(-50%); z-index: 100; opacity: 0.5; transition: all 0.3s; outline: none;}
.slick-arrow:hover { opacity: 1; }
.slick-prev { left: 0; background-image: url('../img/angle-left.svg'); }
.slick-next { right: 0; background-image: url('../img/angle-right.svg'); }
.slick-dots { position: absolute; width: 100%; text-align: center; padding: 0; bottom: rem(30px); }
.slick-dots li { list-style-type: none; display: inline-block; }
.slick-dots li button { border: none; padding: 0; position: relative; width: rem(25px); height: rem(25px); font-size: 0; background: transparent; }
.slick-dots li button:after { content: ""; display: block; position: absolute; background-color: transparent; top: rem(5px); left: rem(5px); width: rem(15px); height: rem(15px); border-radius: 100%; border: 2px solid $brand-primary; opacity: 1; transition: opacity 0.5s; }
.slick-dots li.slick-active button:after { background-color: $theme-primary; }
.slick-dots li button:hover { opacity: 0.75; }
.content-slider .slick-prev { left: -28px }
.content-slider .slick-next { right: -28px }
.content-slider { margin: 0 -$grid-gutter-width/2; }
.content-slider--item { margin: 0 $grid-gutter-width/2; }
#content .slick-slider li { padding: 0; }
#content .slick-slider li:before { background-color: transparent; }


//Animatiertes Menüicon
.mainnav__menu-icon { width: rem(44px); height: rem(44px); position: relative; transform: rotate(0deg); transition: .5s ease-in-out; cursor: pointer; background: none; border: none; }
.mainnav__menu-icon span { display: block; position: absolute; height: rem(2px); width: rem(40px); background: $theme-primary; opacity: 1; left: rem(2px); transform: rotate(0deg); transition: .25s ease-in-out; }
.mainnav__menu-icon span:nth-child(1) { top: 9px; }
.mainnav__menu-icon span:nth-child(2), .mainnav__menu-icon span:nth-child(3) { top: 21px; }
.mainnav__menu-icon span:nth-child(4) { top: 33px; }
.mainnav__menu-icon.open span:nth-child(1) { top: 18px; width: 0; left: 50%; }
.mainnav__menu-icon.open span:nth-child(2) { transform: rotate(45deg); }
.mainnav__menu-icon.open span:nth-child(3) { transform: rotate(-45deg); }
.mainnav__menu-icon.open span:nth-child(4) { top: 18px; width: 0; left: 50%; }
.mainnav__menu-icon:focus { outline: none; }

// Reset Navigation Lists
nav ul { margin: 0; padding: 0; }
nav ul li { list-style-type: none; margin: 0; padding: 0; }


/* ==========================================================================
   Project's custom styles
   ========================================================================== */

*:focus {
  outline-color: $brand-primary;
}

html {
  font-size: 62.5%;
}

html.no-scroll {
  overflow: hidden;
}

body {
  font-size: rem($font-size-base);
  font-family: $font-family-base;
}

h1, h2, h3, h4 {
  margin-top: 0;
  margin-bottom: rem(20px);
}

h1 {
  font-size: rem($font-size-h1 * 0.75);
  color: $text-color-dark;
  font-weight: 600;
}

h2 {
  font-size: rem($font-size-h2 / 28 * 22);
  color: $text-color-dark;
  font-weight: 600;
}

h3 {
  font-size: rem($font-size-h3 * 0.8);
  color: $text-color-dark;
  font-weight: 600;
}

h4 {
  font-size: rem($font-size-h4 * 0.9);
  color: $text-color-dark;
  font-weight: 600;
}

h5 {
  font-size: rem($font-size-h5);
  color: $text-color-dark;
  font-weight: 600;
}

p {
  font-size: rem($font-size-base);
  color: $text-color-dark;
  line-height: rem(24px);
}

p.large {
  font-size: rem($font-size-base-large / 18 * 16);
  color: $text-color-dark;
}

a {
  color: $theme-secondary;
  transition: all 0.3s;
}

a:hover {
  color: darken($theme-secondary,10%);
}

#content img {
  max-width: 100%;
}

section.text {
  padding: rem(50px 0);
}

section.text + section.text {
  padding-top: 0;
}

.themecolor {
  color: $theme-secondary;
}

a.mailadress {
  color: $text-color-dark;
  display: inline-block;
  border-bottom: 1px solid $theme-secondary;
}

button:focus,
a:focus {
  outline: transparent!important;
}

.btn {
  display: block;
  border: 1px solid;
  text-align: center;
  font-size: rem(16px);
  border-radius: 0;
  max-width: rem(300px);
  transition: all 0.3s;
  height: rem(40px);
  padding-top: rem(8px);
}

.btn-white {
  border-color: #FFF;
  color: #FFF;
  box-shadow: inset 0 0 0 0 #FFF;
}

.btn-white:hover {
  background-color: #FFF;
  //box-shadow: inset 0 -66px 0 0 #FFF;
  color: $theme-secondary;
}

.btn-themecolor {
  border-color: $theme-primary;
  color: $theme-primary;
}

.btn-themecolor:hover {
  background: $theme-primary;
  color: #FFF;
}

section.bg-color {
  padding: rem(50px 0);
  background-color: $brand-primary;
}

section.bg-color * {
  color: #FFF;
}


//Animiertes Unterstreichen von der Mitte ausgehend
.underline a {
  position: relative;
  color: #FFF;
  text-decoration: none;
}
.underline a:hover {
  color: #FFF;
}
.underline a:before {
  content: "";
  position: absolute;
  width: 120%;
  height: rem(40px);
  bottom: 0;
  left: -10%;
  background-color: rgba(255,255,255,0.1);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s;
}
.underline a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  h1 {
    font-size: rem($font-size-h1 * 0.65);
  }

  h2 {
    font-size: rem($font-size-h2 * 0.7);
  }

  h3 {
    font-size: rem($font-size-h3 * 0.8);
  }

  h4 {
    font-size: rem($font-size-h4);
  }

  p.large {
    font-size: rem($font-size-base-large);
    line-height: rem(36px);
  }

  .container--slim {
    padding: 0 10%;
  }

  img[align=right] {
    margin: 0 0 25px 25px;
  }

}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {

  h1 {
    font-size: rem($font-size-h1);
  }

  h2 {
    font-size: rem($font-size-h2);
  }

  h3 {
    font-size: rem($font-size-h3);
  }

  h4 {
    font-size: rem($font-size-h4);
  }

  h5 {
    font-size: rem($font-size-h5);
  }

  section.bg-color {
    padding: rem(100px 0);
    background-color: $brand-primary;
  }

}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {

  .container {
    width: 1170px;
  }

}