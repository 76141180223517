.cards {
  padding: rem(30px 0 50px 0);
}

.cards__item {
  position: relative;
  display: block;
  margin-bottom: rem(50px);
  overflow: hidden;
}

.cards__item-image {
  display: block;
  width: 100%;
  height: 200px;
  height: 60vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: rem(15px);
}

.cards__item-headline {
  display: block;
  font-size: rem(24px);
  line-height: rem(28px);
  color: $text-color-dark;
  font-weight: bold;
  margin-bottom: rem(10px);
  padding: rem(0 10px);
  min-height: rem(56px);
}

.cards__item-text {
  display: block;
  font-size: rem(16px);
  color: $text-color-dark;
  margin-bottom: rem(15px);
  padding: rem(0 10px);
}

.cards__item-btn {
  display: block;
  border-color: $theme-secondary;
  color: $theme-secondary;
  font-weight: 400;
  max-width: none!important;
  width: 100%;
}

.cards__item:hover .cards__item-btn {
  background: $theme-secondary;
  color: #FFF;
}

.cards.cards--bgcolor .slick-dots {
  bottom: 0;
}

.cards.cards--bgcolor .slick-dots li button:after {
  background-color: transparent;
  border: 2px solid #fff;
}

.cards.cards--bgcolor .slick-dots li.slick-active button:after {
  background-color: #fff;
}

.cards.cards--bgcolor .cards__item .cards__item-btn {
  background: $theme-primary;
  color: #FFF!important;
}

.cards.cards--bgcolor {
  background-color: transparentize($theme-primary,0.3);
}

.cards.cards--bgcolor .cards__item {
  background-color: #fff;
  padding: rem(20px 0 0 0);
  text-align: center;
  margin-top: rem(20px);
}

.cards.cards--bgcolor .cards__item-headline {
  padding: rem(20px 20px 10px 20px);
}

.cards.cards--bgcolor .cards__item-text {
  padding: rem(0px 20px 50px 20px);
  margin: 0;
}

.cards.cards--bgcolor .cards__item-btn {
  background: transparentize($theme-primary,0.3);
  color: #FFF;
  border: none;
}

.cards.cards--bgcolor .cards__item:hover .cards__item-btn {
  background: $theme-primary;
  color: #fff;
}

.cards.cards--bgcolor .cards__item:hover {
  transform: scale(1.05);
}

.cards__intro {
  display: none;
}

.cards.cards--hasintro .cards__intro {
  display: block;
  text-align: center;
  margin-bottom: rem(50px);
}

.cards.cards--hasintro.cards--bgcolor .cards__intro * {
  color: #FFF;
}

.cards.cards--hasicons .cards__item {
  text-align: center;
}

.cards.cards--hasicons .cards__item-image {
  position: relative;
  background-size: contain;
  height: rem(80px);
  margin-bottom: rem(40px);
}

.cards.cards--hasicons.cards--bgcolor .cards__item {
  background-color: lighten($theme-primary,75%);
}

.cards.cards--hasicons.cards--bgcolor .cards__item * {
  color: $theme-primary;
}

.cards.cards--hasicons.cards--bgcolor .cards__item-text {
  padding: rem(0 20px 50px 20px);
}

.cards.cards--hasicons.cards--bgcolor .cards__item-image {
  font-size: rem(200px);
  line-height: rem(200px);
  height: auto;
}

.cards.cards--hasicons .cards__item:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: rem(2px);
  height: 0;
  background-color: $theme-secondary;
  transition: all 0.3s;
}

.cards.cards--hasicons .cards__item:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: rem(2px);
  height: 0;
  background-color: $theme-secondary;
  transition: all 0.3s;
}

.cards.cards--hasicons.cards--bgcolor .cards__item:after {
  background-color: #FFF;
}

.cards.cards--hasicons .cards__item {
  background-size: contain;
  background-position: center bottom 40px;
  background-repeat: no-repeat;
}

.cards-wide__item {
  padding: rem(50px 30px 40px 30px);
  text-align: center;
  color: #FFF;
  box-shadow: inset 0 0 0 1000px transparentize($theme-primary,0.3);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cards-wide__title {
  font-size: rem(36px);
  margin-bottom: rem(20px);
}

.cards-wide__text {
  font-size: rem(16px);
  margin-bottom: rem(50px);
}

.cards-wide__icon {
  font-size: rem(200px);
  line-height: rem(200px);
  margin-bottom: rem(50px);
}

.cards-wide__item .btn {
  margin: 0 auto;
}

.cards + .cards {
  padding-top: 0;
  margin-top: rem(-50px);
}

.cards + .cards.cards--hasintro {
  padding-top: rem(30px);
  margin-top: 0;
}


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .cards {
    padding: rem(75px 0 15px 0);
  }

  .cards__item {
    margin-bottom: rem(60px);
  }

  .cards__item-image {
    height: rem(230px);
  }

  .cards-wide__item {
    float: left;
    width: 33.3333%;
    overflow: hidden;
  }

  .cards-wide__icon {
    font-size: rem(150px);
    line-height: (150px);
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .cards {
    padding: rem(100px 0 25px 0);
  }

  .cards__item {
    margin-bottom: rem(75px);
  }

  .cards__item-image {
    height: rem(190px);
  }

  .cards-wide__icon {
    font-size: rem(200px);
    line-height: (200px);
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .cards {
    padding: rem(100px 0 20px 0);
  }

  .cards__item {
    margin-bottom: rem(100px);
  }

  .cards__item-image {
    height: rem(230px);
    margin-bottom: rem(30px);
  }

  .cards__item-headline {
    margin-bottom: rem(20px);
  }

  .cards__item-text {
    margin-bottom: rem(30px);
  }

  .cards.cards--bgcolor .cards__item-headline {
    padding: rem(10px 20px 0 20px);
  }
/*
  .cards .slick-dots {
    bottom: rem(0px);
  }
*/
  .cards.cards--hasicons .cards__item-image {
    height: rem(90px);
    margin-bottom: rem(50px);
  }

  .cards.cards--hasicons .cards__item:hover .cards__item-image:after {
    bottom: rem(110px);
  }

}

@media (min-width: 1600px) {

  .cards-wide__item {
    padding: rem(120px);
    text-align: center;
    color: #FFF;
  }

  .cards-wide__icon {
    margin-bottom: rem(100px);
  }

}