#unitegallery-boxed {
  margin: 0 auto;
  width: 100%;
  padding: 50px 0;
}

.ug-thumb-wrapper .ug-thumb-overlay {
  background-color: transparentize($theme-primary,0.4)!important;
  text-align: center;
}

.ug-thumb-wrapper .ug-thumb-overlay .ug-tile-icon.ug-button-play.ug-icon-zoom {
  display:none!important;
}

.ug-thumb-wrapper .ug-thumb-overlay:before {
  font-family: FontAwesome;
  display: block;
  width: rem(44px);
  height: rem(44px);
  content: "\f002";
  color: #FFF;
  font-size: rem(24px);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  #unitegallery-boxed {
    width: rem(720px);
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  #unitegallery-boxed {
    width: rem(940px);
    padding: rem(50px 0 100px 0);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  #unitegallery-boxed {
    width: rem(1130px);
  }

}