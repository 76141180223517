.refslider {
  padding: rem(50px 0);
}

.refslider__item {
  text-align: center;
}

.refslider__itemlist {
  position: relative;
}

.refslider__item-img {
  position: relative;
  height: rem(190px);
  height: 56vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: rem(0 0 20px 0);
}

.refslider .slick-arrow {
  top: 0;
  background-color: $theme-primary;
  transform: none;
  height: rem(190px);
  height: 56vw;
  width: rem(20px);
  background-image: none;
  opacity: 1;
}

.refslider .slick-prev {
  left: 0;
}

.refslider .slick-prev:after {
  content:"\f104";
  font-family: FontAwesome;
  font-size: rem(24px);
  color: #FFF;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: rem(20px);
  height: rem(20px);
  line-height: rem(20px);
}

.refslider .slick-next {
  right: 0;
}

.refslider .slick-next:after {
  content:"\f105";
  font-family: FontAwesome;
  font-size: rem(24px);
  color: #FFF;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: rem(20px);
  height: rem(20px);
  line-height: rem(20px);
}

.refslider__itemlist--nav {
  margin: 0 30px;
}

.refslider__itemlist--nav .refslider__item-img {
  height: rem(45px);
}

.refslider__itemlist--nav .slick-arrow {
  height: rem(45px);
}

.refslider__itemlist--nav .slick-dots {
  bottom: rem(-30px);
}

.refslider__itemlist--nav .refslider__item {
  margin: 0 5px;
}

.refslider__itemlist--nav .refslider__item:hover > div {
  cursor: pointer;
  box-shadow: inset 0 0 0 500px transparentize($theme-primary,0.5);
}

.refslider__itemlist--nav .slick-prev {
  left: rem(-30px);
}

.refslider__itemlist--nav .slick-next {
  right: rem(-30px);
}


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .refslider__item-text {
    padding: rem(0 50px);
  }

  .refslider__item-img {
    height: rem(400px);
  }

  .refslider .slick-prev {
    left: rem(0px);
  }

  .refslider .slick-next {
    right: rem(0px);
  }

  .refslider .slick-arrow {
    background: transparentize($theme-primary,0.3);
    width: rem(44px);
    height: rem(60px);
  }

  .refslider .slick-arrow:hover {
    background: $theme-primary;
  }

  .refslider .slick-prev:after {
    font-size: rem(44px);
    width: rem(44px);
    height: rem(44px);
    line-height: rem(44px);
  }

  .refslider .slick-next:after {
    font-size: rem(44px);
    width: rem(44px);
    height: rem(44px);
    line-height: rem(44px);
  }

  .refslider__itemlist--nav .refslider__item-img {
    height: rem(60px);
  }

  .refslider__itemlist--nav .refslider__item {
    margin: rem(0 10px);
  }

  .refslider__itemlist--nav {
    margin: 0 44px;
  }

  .refslider__itemlist--nav .slick-prev {
    left: rem(-44px);
  }

  .refslider__itemlist--nav .slick-next {
    right: rem(-44px);
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .refslider__item-img {
    height: rem(500px);
  }

  .refslider .slick-arrow {
    height: rem(70px);
  }

  .refslider__itemlist--nav .refslider__item-img {
    height: rem(70px);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .refslider__item-text {
    padding: rem(0 90px);
  }

  .refslider__item-img {
    height: rem(550px);
  }

  .refslider .slick-arrow {
    height: rem(80px);
  }

  .refslider__itemlist--nav .refslider__item-img {
    height: rem(80px);
  }

}